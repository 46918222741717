import axios from 'axios';
import {
    call,
    put,
    select,
    takeLatest,
    takeEvery
} from 'redux-saga/effects';
import {
    submitLoginSuccessAct,
    submitLoginErrorAct,
    f5SubmitLoginSuccessAct,
    f5SubmitRefreshSuccessAct
} from './../AuthLayout/_actions';
import {
    push
} from 'connected-react-router';
import queryString from 'query-string';
import Cookies from 'js-cookie';

export const getStateAuth = (state) => {
    return state.auth;
}

function callHttpExecution(_requestURL, _body) {
    return axios.post(_requestURL, _body);
}

function callGetExecution(_requestURL) {
    try {
        return axios.get(_requestURL);
    } catch (err) {
        console.log("get err ---> ", err);
        return err;
    }
}

function callHttpFormExecution(_requestURL, _body) {
    try {
        const params = new URLSearchParams();
        params.append('username', _body.username);
        params.append('password', _body.pswd);
        return axios.post(_requestURL, params, {
            maxRedirects: 0
        });
    } catch (err) {
        console.log("sts form login err ---> ", err);
        return err;
    }
}

export function* executeLoginFromServer() {
    console.log("login mode ---> ", `${window.REACT_APP_VTV_LOGIN_MODE}`);     
    if (`${window.REACT_APP_VTV_LOGIN_MODE}` === "STS") {
        let requestURL = `${window.REACT_APP_VTV_LOGIN_API}`;
        // Select auth info (from store)
        let stateAuth = yield select(getStateAuth);
        let _body = stateAuth.loginInput;
        try {
            const res = yield call(callHttpFormExecution, requestURL, _body);
            console.log("sts login res ---> ", res);
            let cookieVal = Cookies.get('x-session-cookie')
            console.log("cookie val ---> ", cookieVal);

            yield put(f5SubmitLoginSuccessAct(cookieVal));

            yield executeLogin();
        } catch (err) {
            console.log("sts login err ---> ", err);
            yield executeLogin();
        }
    } else {
        yield executeLogin();
    }
}

export function* executeLogin() {
    let requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/auth/userInfo`;
    
    // Select auth info (from store)
    let stateAuth = yield select(getStateAuth);
    let _body = {
        username: stateAuth.loginInput && stateAuth.loginInput.username,
        password: stateAuth.loginInput && stateAuth.loginInput.pswd
    };

    try {
        const response = yield call(callHttpExecution, requestURL, _body);
        if (response.data.opResult === true) {
            yield put(submitLoginSuccessAct(response.data.data));
            const parsed = queryString.parse(window.location.search);
            if (parsed && parsed.nowId) {
                yield put(push(window.REACT_APP_BASE_PATH + '/admin/communications?nowId=' + parsed.nowId));
            } else {
                const groupRequestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/groups`;
                const groupResponse = yield call(callHttpExecution, groupRequestURL, null)

                console.log('res', groupResponse)

                if (groupResponse && groupResponse.data && groupResponse.data.data && groupResponse.data.data.length) {
                    const groupName = groupResponse.data.data[0].name

                    if (groupName === 'LINEAR') {
                        return yield put(push(window.REACT_APP_BASE_PATH + '/admin/dashboard'));
                    }
                }

                yield put(push(window.REACT_APP_BASE_PATH + '/admin/communications'));
            }

        } else {
            yield put(submitLoginErrorAct(response.data.error));
        }
    } catch (err) {
        yield put(submitLoginErrorAct(err));
        return;
    }
}

export function* executeSTSLogout() {
    if (`${window.REACT_APP_VTV_LOGIN_MODE}` === "STS") {
        yield call(callGetExecution, `${window.REACT_APP_VTV_LOGOUT_API}`);
        console.log('token refreshed CLEANED');
        yield put(f5SubmitRefreshSuccessAct(''));

        /* Clear cookie 'x-session-cookie' */
        Cookies.remove('x-session-cookie');
        Cookies.remove('x-session-cookie', { secure: true });
    }
}

export function* executeRefreshCookies() {
    if (`${window.REACT_APP_VTV_LOGIN_MODE}` === "STS") {
        yield call(callGetExecution, `${window.REACT_APP_VTV_REFRESH_API}`);
        console.log('token refreshed');
        let cookieVal = Cookies.get('x-session-cookie')
        yield put(f5SubmitRefreshSuccessAct(cookieVal));
    }
}

// saga watcher
export default function* getCommunicationsData() {
    yield takeLatest('SUBMIT_LOGIN', executeLoginFromServer);
    yield takeEvery('STS_LOGOUT', executeSTSLogout);
    yield takeEvery('REFRESH_COOKIES', executeRefreshCookies);
}