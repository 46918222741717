import React from "react";
import { Button } from "reactstrap";
import Loader from './../Loader/Loader';

const FixedPlugin = (props) => {

  const getAgencyCode = (_agency_code) => {
    let agencyCode = '--';

    if (_agency_code && _agency_code.length) {
      /* is array? */
      if (Array.isArray(_agency_code)) {
        agencyCode = _agency_code[_agency_code.length - 1];
      } else {
        agencyCode = _agency_code;
      }
    }

    return agencyCode;
  };

  const renderUserProfileListInfo = () => {
    return (
      <React.Fragment>
        <button aria-hidden="true" className="close close-btn" data-dismiss="modal" type="button"
          onClick={() => props.toggleUserInfoPopup()}>
          <i className="nc-icon nc-simple-remove">
          </i>
        </button>
        <dl className="descriptive-data">
          <dt>Nome: </dt>
          <dd>{props.userInfo.fullname || "--"}</dd>
          <dt>Codice Agenzia: </dt>
          <dd>{getAgencyCode(props.userInfo.agency_code)}</dd>
          <dt>Email: </dt>
          <dd>{props.userInfo.mail || "--"}</dd>
          <dt>Unipol ID: </dt>
          <dd>{props.userInfo.unipolId || "--"}</dd>
        </dl>

        <li className="button-container close-button">
          <Button
            color="info"
            block
            className="btn-round"
            target="_blank"
            onClick={() => props.logoutUser()}
          >
            Logout
          </Button>
        </li>
      </React.Fragment>);
  }

  const renderErrorMsg = () => {
    return (
      <React.Fragment >
        <div className="error-msg-popup-container">
          <p className="text-danger">{props.userInfoLoadingError ? 'Si è verificato un errore nel caricamento delle informazioni dell\'utente. Prova a ricaricarle e se il problema persiste, contatta l\'assistenza.' : ''}</p>
        </div>
        <div className="button-container">
          <Button
            color="info"
            block
            className="btn-round"
            target="_blank"
            onClick={() => props.reloadUserInfo()}
          >
            Ricarica
          </Button>
        </div>
      </React.Fragment >
    );
  }

  let showPopupClass = props.showPopupUserInfo ? 'show' : '';
  let UserProfileInfo = (props.userInfo) ? renderUserProfileListInfo() : null;
  let ErrorMsg = renderErrorMsg();
  return (
    <div className="fixed-plugin">
      <div className={"dropdown " + showPopupClass}>
        <ul className="dropdown-menu show">
          {props.userInfoIsLoading ? <Loader /> : null}

          {props.userInfoIsLoading || props.userInfoLoadingError ? null : UserProfileInfo}
          {props.userInfoLoadingError ? ErrorMsg : null}
        </ul>
      </div>
    </div>
  );
}

export default FixedPlugin;
