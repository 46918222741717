import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "containers/Layout/Admin.jsx";
import AuthLayout from "containers/AuthLayout/AuthLayout.jsx";

import "assets/css/bootstrap/dist/css/bootstrap.css";

import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { Provider } from 'react-redux';
import createStore, { history } from './utils/storeConfig';
import { ConnectedRouter } from 'connected-react-router'
import applyAxiosInterceptor from './utils/http-interceptor-config';

const store = createStore;

/*
  For different environment, to be compatible with Drakkar version, we need to copy process.env variables to window object
*/
(() => {
  /* istanbul ignore next */
  if (window.location.href.indexOf('localhost') >= 0 && window.location.href.indexOf('localhost:8080') < 0) {
    window.REACT_APP_API_ENDPOINT_SUPPORT = process.env.REACT_APP_API_ENDPOINT_SUPPORT
    window.REACT_APP_BASE_PATH = process.env.REACT_APP_BASE_PATH
    window.REACT_APP_VTV_LOGIN_API = process.env.REACT_APP_VTV_LOGIN_API
    window.REACT_APP_VTV_LOGOUT_API = process.env.REACT_APP_VTV_LOGOUT_API
    window.REACT_APP_VTV_REFRESH_API = process.env.REACT_APP_VTV_REFRESH_API
    window.REACT_APP_VTV_LOGIN_MODE = process.env.REACT_APP_VTV_LOGIN_MODE
    window.REACT_APP_MIN_LOADING_TIME = process.env.REACT_APP_MIN_LOADING_TIME
    window.LOCAL_REACT_APP_API_ENDPOINT_SUPPORT = process.env.LOCAL_REACT_APP_API_ENDPOINT_SUPPORT
    window.MOCK_REACT_APP_API_ENDPOINT_SUPPORT = process.env.MOCK_REACT_APP_API_ENDPOINT_SUPPORT
  }
})()

/* istanbul ignore next */
applyAxiosInterceptor();

/* istanbul ignore next */
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <>
        <Switch>
          <Route path={window.REACT_APP_BASE_PATH + "/admin"} render={props => <AdminLayout {...props} />} />
          <Route path={window.REACT_APP_BASE_PATH + "/auth"} render={props => <AuthLayout {...props} />} />
          <Redirect to={window.REACT_APP_BASE_PATH + "/admin/communications"} />
        </Switch>
      </>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
