/**
 * Gets the repositories of the user from Github
 */
import axios from 'axios';
import {
    call, put, takeLatest
} from 'redux-saga/effects';
import { loadUserInfoSuccessAct, loadUserInfoErrorAct, loadAppConfigSuccessAct, loadAppConfigErrorAct } from './_actions';
//import request from 'utils/request';
//import { makeSelectUsername } from 'containers/HomePage/selectors';

export const getStateCommunications = (state) => state.communications

/** function that returns an axios call */
function getUserDataHttp(requestURL) {
    return axios.get(requestURL);
}

export function* getUserDataFromServer() {
    //const stateCommunications = yield select(getStateCommunications);
    //let requestURL_old = `http://localhost:3004/userInfoError`;
    //let requestURL_old = `http://localhost:3004/userInfo`;
    let requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/userInfo`;

    try {
        const response = yield call(getUserDataHttp, requestURL);
        if (response.data.opResult === true) {
            yield put(loadUserInfoSuccessAct(response.data.data));
        } else {
            yield put(loadUserInfoErrorAct(response && response.data && response.data.error));
        }
    } catch (err) {
        console.log(err)
        yield put(loadUserInfoErrorAct(err));
        return;
    }
}

const activateHotjar = (_hjid)=> {
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:_hjid,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    //   console.log("activateHotjar");
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}

export function* getAppConfigFromServer() {
    //const stateCommunications = yield select(getStateCommunications);
    let requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/configuration`;

    try {
        const response = yield call(getUserDataHttp, requestURL);
        if (response.data.opResult === true) {
            yield put(loadAppConfigSuccessAct(response.data.data));
            activateHotjar(response.data.data.hjid);
        } else {
            yield put(loadAppConfigErrorAct(response.data.error));
        }
    } catch (err) {
        yield put(loadAppConfigErrorAct(err));
        return;
    }
}

// saga watcher
export default function* getUserData() {
    yield takeLatest('LOAD_USER_INFO', getUserDataFromServer);
    yield takeLatest('LOAD_APP_CONFIG', getAppConfigFromServer);
}
