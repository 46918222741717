import axios from 'axios';
import { closeDefaultModalAct } from '../containers/Layout/_actions';
import { refreshCookiesAct, stsLogoutAct, LogoutAct } from './../containers/AuthLayout/_actions';
import { push } from 'connected-react-router';
import createStore from './storeConfig';
import queryString from 'query-string';

const stsUrls = [
  window.REACT_APP_VTV_LOGIN_API,
  window.REACT_APP_VTV_LOGOUT_API,
  window.REACT_APP_VTV_REFRESH_API
]

const store = createStore

const requestHandler = (request) => {
  const { dispatch } = store;
  if (localStorage.getItem('applicationState') !== null) {
    let appState = JSON.parse(localStorage.getItem('applicationState')) // re-hydrate the store
    const authInfoToken = appState.auth && appState.auth.authInfo && appState.auth.authInfo.token
    if (authInfoToken) {
      request.headers['Authorization'] = `Bearer ${authInfoToken}`;
    }
    request.headers['Pragma'] = 'no-cache';
  }

  const foundStsUrl = stsUrls.find(_url => (request.url && request.url.endsWith(_url)) )
  if (!foundStsUrl) {
    dispatch(refreshCookiesAct());
  }

  return request;
}

const applyAxiosInterceptor = () => {

  const { dispatch } = store;

  const logout = () => {
    if (!window.location || window.location.pathname !== (window.REACT_APP_BASE_PATH + '/auth/login')) {
      dispatch(closeDefaultModalAct())
      dispatch(LogoutAct())
      let routeStr = window.REACT_APP_BASE_PATH + '/auth/login';
      const parsed = queryString.parse(window.location.search);
      if (parsed && parsed.nowId) {
        routeStr += `?nowId=${parsed.nowId}`
      }
      dispatch(push(routeStr))
    }
  };


  axios.interceptors.request.use(
    request => requestHandler(request)
  )
  // Define Axios interceptors
  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error && error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 302)) {      
      // Manage 401 not authorized here
      // check if the url does not ends as one of the Sts url
      const foundStsUrl = stsUrls.find(_url => (error.response.request.responseURL && error.response.request.responseURL.endsWith(_url)) )
      if (!foundStsUrl) {
        dispatch(stsLogoutAct());
      }
      logout();

      // dispatch(openDefaultModalAct({
      //   title: 'Operazione non consentita',
      //   mainText: 'Si è verificato un problema di autorizzazione nell\'operazione corrente. Prova a riprendere l\'esecuzione dell\'applicativo. Se il problema persiste, contatta l\'assistenza.',
      //   closeAndSaveHandlerFunctionName: logout,
      //   okBtnLabel: 'VAI AL LOGIN'
      // }));
    }
    return error;
  });
};

export default applyAxiosInterceptor;