import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {
    submitLoginAct, resetStorageAct
} from './../AuthLayout/_actions';
import { withRouter } from "react-router-dom";
import Loader from './../../components/Loader/Loader';
import logo from "logo-vtv-support-login-azzurro.png";
import queryString from "query-string";

const LoginPage = (props) => {
    const [inputData, setInputData] = useState({
        username: '',
        pswd: '',
    })
    const [minTimeLoading, setMinTimeLoading] = useState(false)
    const [minLoadingTimeout, setMinLoadingTimeout] = useState(null)

    const goToApp = () => {
        const parsed = queryString.parse(props.location.search);
        if (parsed && parsed.nowId) {
            props.history.push(window.REACT_APP_BASE_PATH + '/admin/communications?nowId=' + parsed.nowId);
        }
        else {
            props.history.push(window.REACT_APP_BASE_PATH + '/admin/communications');
        }
    }

    useEffect(() => {
        /* If user has already logged in, then redirect to communication page */
        const authInfoData = props.auth && props.auth.authInfo;
        if (authInfoData && authInfoData.token) {
            goToApp();
        }

        return () => {
            minLoadingTimeout && clearTimeout(minLoadingTimeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (evt) => {
        const { name, value } = evt.target;
        setInputData(currVal => ({ ...currVal, [name]: value }));
    }

    const startMinimumLoading = () => {
        if (minTimeLoading) {
            return;
        }
        setMinTimeLoading(true)
        const timeoutRef = setTimeout(() => {
            setMinTimeLoading(false)
        }, 600);
        setMinLoadingTimeout(timeoutRef)
    }

    const handleSubmit = (event) => {
        startMinimumLoading();
        const { username, pswd } = inputData;
        props.submitLogin({ username, pswd });
        event.preventDefault();
    }

    const isInputValid = inputData.username && inputData.pswd;
    const loadingVisible = (props.isLoginLoading || minTimeLoading);
    const errorOccurred = props.auth && props.auth.loginError;

    return (
        <>
            <div className="wrapper wrapper-full-page ps section-image">
                <div className="login-page">
                    {/* {(isLoginLoading || this.state.minTimeLoading) ? <Loader /> : null} */}
                    <div className="container">
                        <div className="row">
                            <div className="ml-auto mr-auto col-md-6 col-lg-4">
                                <form onSubmit={handleSubmit} className="form">
                                    <div className="card-login card">

                                        <div className={"loader-container " + (loadingVisible ? 'loading-visible' : '')}>
                                            <div><Loader />
                                            </div>
                                        </div>

                                        <div className="card-header">
                                            <div className="card-header">

                                                <div className="header-container">
                                                    <div className="logo-img">
                                                        <img src={logo} alt="react-logo" />
                                                    </div>
                                                </div>

                                                <p className="text-description">Effettua il <strong>Login</strong> per seguire le comunicazioni, trovare tutte le informazioni utili e portare avanti il loro stato direttamente dal portale.</p>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="nc-icon nc-single-02">
                                                        </i>
                                                    </span>
                                                </div>
                                                <input placeholder="Nome Utente..."
                                                    type="text"
                                                    name="username"
                                                    value={inputData.username}
                                                    onChange={handleChange}
                                                    className="form-control" />
                                            </div>
                                            <div className="input-group"><div className="input-group-prepend">
                                                <span className="input-group-text"><i className="nc-icon nc-key-25">
                                                </i>
                                                </span>
                                            </div>
                                                <input placeholder="Password"
                                                    autoComplete="off"
                                                    type="password"
                                                    name="pswd"
                                                    value={inputData.pswd}
                                                    onChange={handleChange}
                                                    className="form-control" />
                                            </div>
                                        </div>

                                        <div className={"error-msg-info " + (errorOccurred ? 'element-shown' : '')}>
                                            <p>Si è verificato un errore in fase di login. Controlla la correttezza dei dati forniti e ripeti l'operazione.</p>
                                        </div>

                                        <div className="card-footer">
                                            <input type="submit"
                                                className={"btn-round mb-3 btn btn-info btn-block " + (!isInputValid ? 'disabled' : '')}
                                                value="Procedi" />
                                        </div>

                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth
})

export const mapDispatchToProps = (dispatch, ownProps) => ({
    submitLogin: (pageInfo) => dispatch(submitLoginAct(pageInfo)),
    resetStorage: (pageInfo) => dispatch(resetStorageAct())
})

export const LoginPageNotConnected = LoginPage;

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage))

