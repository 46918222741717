
export const submitLoginAct = (loginInfo) => ({
  type: 'SUBMIT_LOGIN',
  payload: loginInfo
})

export const f5SubmitLoginSuccessAct = f5LoginResponseInfo => ({
  type: 'F5_SUBMIT_LOGIN_SUCCESS',
  payload: f5LoginResponseInfo
})

export const f5SubmitRefreshSuccessAct = f5RefreshResponseInfo => ({
  type: 'F5_REFRESH_TOKEN_SUCCESS',
  payload: f5RefreshResponseInfo
})

export const submitLoginSuccessAct = loginResponseInfo => ({
  type: 'SUBMIT_LOGIN_SUCCESS',
  payload: loginResponseInfo
})

export const submitLoginErrorAct = errorInfo => ({
  type: 'SUBMIT_LOGIN_ERROR',
  payload: errorInfo
})

export const LogoutAct = () => ({
  type: 'LOGOUT'
})

export const stsLogoutAct = () => ({
  type: 'STS_LOGOUT'
})

export const refreshCookiesAct = () => ({
  type: 'REFRESH_COOKIES'
})


export const resetStorageAct = () =>({
  type: 'RESET_STORAGE'
})
