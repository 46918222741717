import {
    createStore,
    applyMiddleware,
    compose
} from 'redux';
import {
    createBrowserHistory
} from 'history'
import {
    routerMiddleware
} from 'connected-react-router'
//import rootReducer from './../reducers.js';
import createRootReducer from './../reducers.js';
import createSagaMiddleware from 'redux-saga';
import layoutSaga from '../containers/Layout/saga';
import loginSaga from './../containers/login/saga';

import {
    initialState as authInitialState
} from './../containers/AuthLayout/_reducers';
import {
    initialState as mainInitialState
} from '../containers/Layout/_reducers';

export const history = createBrowserHistory()

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

// const actionsToTriggerSaveSessionStorage = [
//     'LOAD_USER_INFO_SUCCESS',
//     'LOAD_APP_CONFIG_SUCCESS',
//     'SUBMIT_LOGIN_SUCCESS',
//     'SHOW_LOADING_SPINNER'
// ]

const actionsToTriggerSaveLocalStorage = [
    'SUBMIT_LOGIN_SUCCESS',
    'F5_SUBMIT_LOGIN_SUCCESS',
    'F5_REFRESH_TOKEN_SUCCESS',
    'LOGOUT'
]

// const actionsToTriggerResetStorage = [
//     'LOGOUT'
// ]

// const getMapDataToSave = (mapData) => {
//     return {
//         viewport: mapData.viewport && {
//             zoom: mapData.viewport.zoom,
//             latitude: mapData.viewport.latitude,
//             longitude: mapData.viewport.longitude
//         }
//     }
// }

// const removeStateLoading = (stateToSave) => {
//     stateToSave.main.userInfoIsLoading = false;
//     stateToSave.main.appConfigIsLoading = false;
//     stateToSave.main.showPopupUserInfo = false;
//     stateToSave.main.showDefaultModal = false;
//     stateToSave.main.modalData = {};
//     stateToSave.main.appConfigLoadingError = false;
//     stateToSave.main.userInfoLoadingError = false;
// }

// const getLoObj_sessionStorage_reset = (_store) => {
//     let stateToSave = {};
//     stateToSave.main = mainInitialState;

//     /* Remove Loading */
//     removeStateLoading(stateToSave);

//     return stateToSave;
// }

// const getLoObj_sessionStorage = (_store) => {
//     let stateToSave = {
//         main: _store.main,
//     };

//     stateToSave = Object.assign({}, stateToSave, {});
//     stateToSave.main = Object.assign({}, stateToSave.main, {});

//     /* Remove Loading */
//     removeStateLoading(stateToSave);

//     return stateToSave;
// }

const getLoObj_localStorage = (_store) => {
    let stateToSave = {
        auth: _store.auth
    };
    stateToSave.auth = Object.assign({}, stateToSave.auth, {});
    stateToSave.auth.loginInput = Object.assign({}, {});
    /* Remove Loading */
    stateToSave.auth.isLoginLoading = false;

    return stateToSave;
}

/**
 * Add all the state in local storage
 * @param getState
 * @returns {function(*): function(*=)}
 */
const sessionStorageMiddleware = ({
    getState
}) => {
    return (next) => (action) => {
        const result = next(action);
        // // Session storage
        // if (actionsToTriggerSaveSessionStorage.indexOf(action.type) >= 0) {
        //     const sessionStorageObj = getLoObj_sessionStorage(getState());
        //     // Select properties to store in SessionStorage
        //     sessionStorage.setItem('applicationState', JSON.stringify(
        //         sessionStorageObj
        //     ));
        // }
        // Local storage
        if (actionsToTriggerSaveLocalStorage.indexOf(action.type) >= 0) {
            const localStorageObj = getLoObj_localStorage(getState());
            // Select properties to store in localStorage
            localStorage.setItem('applicationState', JSON.stringify(
                localStorageObj
            ));
        }
        // // Reset Session storage and rehydrate
        // if (actionsToTriggerResetStorage.indexOf(action.type) >= 0) {
        //     const resetSessionStorageObj = getLoObj_sessionStorage_reset(getState());
        //     // Select properties to store in SessionStorage
        //     sessionStorage.setItem('applicationState', JSON.stringify(
        //         resetSessionStorageObj
        //     ));
        //     //reHydrateStore();
        // }
        return result;
    };
};

const reHydrateStore = () => {
    if (sessionStorage.getItem('applicationState') !== null) {
        let appState = JSON.parse(sessionStorage.getItem('applicationState')) // re-hydrate the store
        appState.main = Object.assign({}, mainInitialState, appState.main);

        if (localStorage.getItem('applicationState') !== null) {
            let authState = JSON.parse(localStorage.getItem('applicationState')) // re-hydrate the store
            appState.auth = Object.assign({}, authInitialState, authState.auth);
        }

        return appState;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
    createRootReducer(history), // root reducer with router state
    //rootReducer,
    reHydrateStore(),
    composeEnhancers(
        applyMiddleware(routerMiddleware(history), sagaMiddleware, sessionStorageMiddleware)
    )
)

// then run the saga
sagaMiddleware.run(layoutSaga)
sagaMiddleware.run(loginSaga);