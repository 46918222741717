import React, { useEffect, useRef } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

//redux routing saga
import { Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import {
  loadUserInfoAct,
  loadAppConfigAct
} from './_actions';

import routes from "routes.js";
import UserInfoPopup from './UserInfoPopup';
import { SecureRoute } from '../../utils/secured-route';
import SelectedComponentRouteGuard from '../../utils/selected-component-route-guard';

import "vitruvius-support-module/src/scss/core.scss";
import "vitruvius-support-module/src/assets/scss/paper-dashboard.scss";
import "vitruvius-support-module/src/assets/demo/demo.css";

var ps;

const Dashboard = (props) => {
  const mainPanel = useRef()

  useEffect(() => {
    if (!navigator.platform || navigator.platform.indexOf("Win") > -1) {  //WARNING controllare che in produzione questa condizione non causi problemi
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    // Load app config data
    props.loadAppConfig();
    // Load user info data    
    props.loadUserInfo();

    return () => {
      if (!navigator.platform || navigator.platform.indexOf("Win") > -1) {  //WARNING controllare che in produzione questa condizione non causi problemi
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <div className="wrapper">
        <div className="main-panel" id="main-panel" ref={mainPanel}>
          <Switch>
            {routes.map((prop, key) => {
              return (
                <SecureRoute
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                  routeGuard={new SelectedComponentRouteGuard(props)}
                  redirectToPathWhenFail={window.REACT_APP_BASE_PATH + "/admin/communications"}
                />
              );
            })
            }
            <Redirect to={window.REACT_APP_BASE_PATH + "/admin/communications"} />
          </Switch>
        </div>
        <UserInfoPopup />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state, ownProps) => ({
  ...state.main,
})

export const mapDispatchToProps = (dispatch, ownProps) => ({
  loadAppConfig: () => dispatch(loadAppConfigAct()),
  loadUserInfo: () => dispatch(loadUserInfoAct())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
