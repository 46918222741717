import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { VtvSupport } from "vitruvius-support-module";
import logo from "vitruvius-support-module/src/assets/logo-vtv-support-top.png";
import { toggleUserInfoPopupAct } from "../Layout/_actions";

const CommunicationsDashboardPage = (props) => {
  const goToHome = () => {
    props.history.push(window.REACT_APP_BASE_PATH + "/admin/dashboard");
  };

  const goToCommunications = () => {
    props.history.push(window.REACT_APP_BASE_PATH + "/communications");
  };

  const userInfoToggle = () => {
    props.toggleUserInfoPopup();
  };

  const header = {
    show: true,
    logoClickHandler: goToHome,
    changedComponentHandler: goToHome,
    logo: logo,
    toggleUserInfoPopupHandler: userInfoToggle,
  };

  return (
    <VtvSupport
      showCommunications={false}
      showDashboard={true}
      showDetails={false}
      // communications_goToDetails={goToDetails}
      goToCommunications={goToCommunications}
      goToDashboard={goToHome}
      componentKey={Date.now()}
      header={header}
      takeAllSentAndFailed={true}
    />
  );
};

const mapStateToProps = (state, ownProps) => ({});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleUserInfoPopup: () => dispatch(toggleUserInfoPopupAct()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommunicationsDashboardPage)
);
