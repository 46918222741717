export default class SelectedComponentRouteGuard {
    constructor(props) {
        this.props = props
    }

    shouldRoute() {
        // If not in communications, than check that there is a selected component. If not, go to communications
        const _pathname = this.props.history && this.props.history.location && this.props.history.location.pathname;

        const isDashboard = _pathname && _pathname === window.REACT_APP_BASE_PATH + '/admin/dashboard'

        if (isDashboard) {
            return true
        }

        if (_pathname && (_pathname.indexOf((window.REACT_APP_BASE_PATH + '/admin/communications')) < 0)) {
            const _selectedComponent = this.props.communications.selectedComponent;
            if (!_selectedComponent) {
                return false;
            }
        }

        return true;
    }
}