import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import FixedPlugin from '../../components/FixedPlugin/FixedPlugin'
import { toggleUserInfoPopupAct, loadUserInfoAct } from './_actions';
import { LogoutAct, stsLogoutAct } from '../AuthLayout/_actions';

const mapStateToProps = (state, ownProps) => ({
  ...state.main
})

export const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleUserInfoPopup: () => dispatch(toggleUserInfoPopupAct()),
  reloadUserInfo: () => dispatch(loadUserInfoAct()),
  logoutUser: () => {
    dispatch(toggleUserInfoPopupAct())
    dispatch(LogoutAct())
    dispatch(stsLogoutAct())
    dispatch(push(window.REACT_APP_BASE_PATH + '/auth/login'))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FixedPlugin)
