import React, { useEffect, useRef } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
//redux routing saga
import { Switch, Redirect } from "react-router-dom";
import { authRoutes } from "routes.js";
import { SecureRoute } from '../../utils/secured-route';

var ps;

const AuthLayout = () => {
  const mainPanel = useRef()

  useEffect(() => {
    if (!navigator.platform || navigator.platform.indexOf("Win") > -1) { //WARNING controllare che in produzione questa condizione non causi problemi
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }

    // if (e.history.action === "PUSH") {
    //   if (mainPanel && mainPanel.current) {
    //     mainPanel.current.scrollTop = 0;
    //   }
    //   if (document.scrollingElement) {
    //     document.scrollingElement.scrollTop = 0;
    //   }
    // }

    return () => {
      if (!navigator.platform || navigator.platform.indexOf("Win") > -1) { //WARNING controllare che in produzione questa condizione non causi problemi
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    }
  }, [])

  return (
    <React.Fragment>
      <div className="wrapper">
        <div className="main-panel" id="main-panel" ref={mainPanel}>
          <Switch>
            {authRoutes.map((prop, key) => {
              return (
                <SecureRoute
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                  redirectToPathWhenFail={window.REACT_APP_BASE_PATH + "/"}
                />
              );
            })
            }
            <Redirect to={window.REACT_APP_BASE_PATH + "/auth/login"} />
          </Switch>
          {/*<Footer fluid />*/}
        </div>
      </div>
    </React.Fragment>
  );
}

export const AuthLayoutNotConnected = AuthLayout;

export default AuthLayout
