import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { layoutReducer } from './containers/Layout/_reducers'
import { authReducer } from './containers/AuthLayout/_reducers'

const createRootReducer = (history) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    main: layoutReducer,
    auth: authReducer
  })

  const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
      state = undefined
    }
    return appReducer(state, action)
  }

  return rootReducer;
}


export default createRootReducer