import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { VtvSupport } from "vitruvius-support-module";
import "vitruvius-support-module/src/scss/core.scss";

import "vitruvius-support-module/src/assets/scss/paper-dashboard.scss";
import "vitruvius-support-module/src/assets/demo/demo.css";
import logo from "vitruvius-support-module/src/assets/logo-vtv-support-top.png";
import { toggleUserInfoPopupAct } from "./../Layout/_actions";

const CommunicationDetailsPage = (props) => {
  const goToHome = () => {
    props.history.push(window.REACT_APP_BASE_PATH + "/communications");
  };

  const goToDashboard = () => {
    props.history.push(window.REACT_APP_BASE_PATH + "/admin/dashboard");
  };

  const userInfoToggle = () => {
    props.toggleUserInfoPopup();
  };

  const header = {
    show: true,
    logoClickHandler: goToHome,
    changedComponentHandler: goToHome,
    logo: logo,
    toggleUserInfoPopupHandler: userInfoToggle,
  };

  return (
    <div className="embed-container">
      <VtvSupport
        showCommunications={false}
        showDetails={true}
        header={header}
        goBack={goToHome}
        goToDashboard={goToDashboard}
        documentsGridView={true}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({});

export const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleUserInfoPopup: () => dispatch(toggleUserInfoPopupAct()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommunicationDetailsPage)
);
