import CommunicationsPage from "containers/communications/CommunicationsPage.jsx";
import CommunicationDetailsPage from "containers/communication-details/CommunicationDetailsPage.jsx";
import LoginPage from "containers/login/LoginPage.jsx";
import CommunicationsDashboardPage from "containers/communications-dashboard/CommunicationsDashboardPage";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bullet-list-67",
    layout: window.REACT_APP_BASE_PATH + "/admin",
    component: CommunicationsDashboardPage
  },
  {
    path: "/communications/details",
    name: "Communication Detail",
    hidden: true,
    icon: "nc-icon nc-diamond",
    component: CommunicationDetailsPage,
    layout: window.REACT_APP_BASE_PATH + "/admin"
  },
  {
    path: "/communications",
    name: "Communications",
    icon: "nc-icon nc-bullet-list-67",
    layout: window.REACT_APP_BASE_PATH + "/admin",
    component: CommunicationsPage
  },
  {
    path: "/details",
    name: "Communication Detail",
    hidden: true,
    icon: "nc-icon nc-diamond",
    component: CommunicationDetailsPage,
    layout: window.REACT_APP_BASE_PATH + "/admin"
  },
];


export const authRoutes = [
  {
    path: "/login",
    name: "Login",
    hidden: true,
    icon: "nc-icon nc-diamond",
    component: LoginPage,
    layout: window.REACT_APP_BASE_PATH + "/auth"
  },
];

export default routes;
