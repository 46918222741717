import React, { useEffect, useState } from "react";
import { Route, Redirect } from 'react-router-dom'
import { Observable } from 'rxjs'

/* Example of route guard taken from npm package 'react-route-guard' (https://github.com/pixelfusion/react-route-guard#readme) */

/**
 * Debug logging theme
 */
const SecureRouteLoggerConsoleTheme = {
    normal: '',
    testing: 'color: darkcyan; font-size: 0.7rem; font-style: italic;',
    important: 'color: green; font-size: 0.7rem; font-style: normal; font-weight: bold',
    error: 'color: red; font-size: 0.7rem; font-style: normal; font-weight: bold'
};

/**
 * Debug loggin
 */
const debugLogger = (className, methodName, msg, displayFormat, extraData) => {

    const messageToPrint = displayFormat ? `%c[${className} - ${methodName}] ${msg}` : `[${className} - ${methodName}] ${msg}`;

    if (displayFormat) {
        if (extraData) {
            console.log(messageToPrint, displayFormat, extraData);
        } else {
            console.log(messageToPrint, displayFormat);
        }
    } else {
        if (extraData) {
            console.log(messageToPrint, extraData);
        } else {
            console.log(messageToPrint);
        }
    }
}

export const SecureRoute = (props) => {
    const [guardState, setGuardState] = useState({
        hasRouteGuard: props.routeGuard ? true : false,
        routeGuardFinished: false,
        routeGuardResult: null
    })

    useEffect(() => {
        const computeGuardState = async () => {
            if (!guardState.hasRouteGuard) {
                return
            }
            const tempRouteGuardResult = props.routeGuard.shouldRoute()
            if (typeof (tempRouteGuardResult) === 'boolean') {
                setGuardState(prevState => ({
                    hasRouteGuard: prevState.hasRouteGuard,
                    routeGuardFinished: true,
                    routeGuardResult: tempRouteGuardResult
                }))
            } else if (tempRouteGuardResult instanceof Promise) {
                tempRouteGuardResult.then(result => {
                    setGuardState(prevState => ({
                        hasRouteGuard: prevState.hasRouteGuard,
                        routeGuardFinished: true,
                        routeGuardResult: result
                    }))
                })
            } else if (tempRouteGuardResult instanceof Observable) {
                tempRouteGuardResult
                    .take(1)
                    .subscribe(result => {
                        setGuardState(prevState => ({
                            hasRouteGuard: prevState.hasRouteGuard,
                            routeGuardFinished: true,
                            routeGuardResult: result
                        }))
                    })
            }
        }

        computeGuardState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const successRoute = <Route {...props} />

    // If hasn't `routeGuard` props, then just render the real <Route>
    if (!guardState.hasRouteGuard) {
        if (props.enableDebug) {
            debugLogger(constructor.name, `render`, `no route guard to run, render normal <Route> directly.`, SecureRouteLoggerConsoleTheme.testing)
        }

        return successRoute
    }

    const redirectPath = props.redirectToPathWhenFail ? props.redirectToPathWhenFail : (window.REACT_APP_BASE_PATH + '/')
    const failRedirect = <Redirect to={redirectPath} />
    const failComponentRoute = props.componentWhenFail ? <Route path={props.path} component={props.componentWhenFail} /> : null

    if (guardState.routeGuardFinished) {
        if (props.enableDebug) {
            let debugMsg = `route guard passed, render <Route>.`,
                className = constructor.name,
                debugTheme = SecureRouteLoggerConsoleTheme.testing

            if (!guardState.routeGuardResult) {
                debugMsg = `route guard fail, render <Redirect to=${redirectPath} />`
                debugTheme = SecureRouteLoggerConsoleTheme.error
            }

            debugLogger(className, `render`, debugMsg, debugTheme)
        }

        if (guardState.routeGuardResult) {
            return successRoute
        } else {
            // `componentWhenFail` got higher priority than `redirectToPathWhenFail`
            return props.componentWhenFail ? failComponentRoute : failRedirect
        }
    } else {
        return null
    }
}